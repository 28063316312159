import { useQuery } from "@apollo/client";
import HighChartsReact from "highcharts-react-official";
import * as HighCharts from "highcharts/highcharts";
import highChartsMore from "highcharts/highcharts-more";
import solidGauge from "highcharts/modules/solid-gauge";
import { useMemo } from "react";
import { useCurrentUser } from "../../utils/hooks";
import { INVESTMENT_COMPLETENESS_QUERY } from "./graphql";

if (typeof HighCharts === "object" && typeof window !== "undefined") {
  highChartsMore(HighCharts);
  solidGauge(HighCharts);
}
if (HighCharts === undefined) throw new Error("HighCharts is not defined");

declare interface CustomPoint extends HighCharts.Point {
  custom?: {
    totalY: number;
    percentComplete: string;
  };
  innerRadius: string;
  radius: string;
}
declare interface CustomSeriesGaugeOptions
  extends HighCharts.SeriesSolidgaugeOptions {
  custom: {
    startAngle: number;
    endAngle: number;
  };
  data: [CustomPoint];
}

declare interface ChartTooltip
  extends Omit<HighCharts.TooltipOptions, "pointFormatter"> {
  pointFormatter: HighCharts.FormatterCallbackFunction<CustomPoint>;
}

declare interface ChartOptions extends Omit<HighCharts.Options, "tooltip"> {
  tooltip: ChartTooltip;
}

function InvestmentCompletenessChart({
  investmentId,
}: {
  investmentId: number;
}): JSX.Element {
  const currentUser = useCurrentUser();

  const { data, loading } = useQuery(INVESTMENT_COMPLETENESS_QUERY, {
    variables: {
      investmentId,
    },
  });

  const chartSeries = useMemo<CustomSeriesGaugeOptions[]>(() => {
    if (!data) return [];
    let totalCategoriesSoFar = 0;

    return data.investmentCompleteness.flatMap((item, i) => {
      let cumulative = 0;
      return (item.data || []).map(subItem => {
        const RING_WIDTH = 10;
        const RING_DIAMETER = 100;
        const EMPTY_RING_SIZE = 5;

        const subItemValue = (subItem.value || 0) * 100.0 || EMPTY_RING_SIZE;
        totalCategoriesSoFar++;
        cumulative += subItemValue;

        const startAngle = ((cumulative - subItemValue) / 100.0) * 360.0;
        const endAngle = startAngle + 360;

        const innerRadius = `${RING_DIAMETER - i * RING_WIDTH}%`;
        const radius = `${RING_DIAMETER - (i + 1) * RING_WIDTH}%`;

        const completenessColor =
          (HighCharts.getOptions()?.colors || [])[i] || "#000000";

        return {
          name: item.category,
          type: "solidgauge",
          subCategory: subItem.subCategory,
          custom: {
            startAngle,
            endAngle,
          },
          yAxis: totalCategoriesSoFar - 1,
          data: [
            {
              name: subItem.subCategory,
              y: subItemValue,
              innerRadius,
              radius,
              custom: {
                totalY:
                  (item.data || []).reduce(
                    (acc, curr) => acc + (curr.value || 0),
                    0
                  ) * 100.0,
                percentComplete: (
                  (subItem?.percentComplete || 0) * 100
                ).toFixed(0),
              },
              color: subItem.value
                ? completenessColor
                : new HighCharts.Color(completenessColor).setOpacity(0.3).get(),
            },
          ] as [CustomPoint],
        } as CustomSeriesGaugeOptions;
      });
    });
  }, [data]);

  const options = useMemo<ChartOptions>(() => {
    const percentComplete =
      ((data?.investmentCompleteness || []).reduce<number>((accu, item) => {
        return (
          accu +
          (item.data || []).reduce((acc, curr) => acc + (curr.value || 0), 0)
        );
      }, 0) /
        (data?.investmentCompleteness || []).length) *
      100;

    return {
      credits: {
        enabled: false,
      },
      chart: {
        backgroundColor: "transparent",
        type: "solidgauge",
        width: 150,
        height: 200,
        margin: [0, 0, 0, 0], // Top, Right, Bottom, Left margins
        spacing: [0, 0, 0, 0],
      },
      title: {
        text: "Data Coverage",
        style: {
          fontSize: "14px",
        },
        y: 20,
      },

      subtitle: {
        text: `${percentComplete.toFixed(0)}%`,

        style: {
          fontSize: "14px",
          //share color between gray and green be a range of colors based on percent complete
          color: percentComplete > 50 ? "#4CAF50" : "#9E9E9E",
        },
        y: 105,
      },

      tooltip: {
        borderWidth: 0,
        backgroundColor: "none",
        shadow: false,
        style: {
          fontSize: "14px",
        },
        valueSuffix: "%",
        pointFormatter: function () {
          if (this.name) {
            return `
            <br><span style="color:${this.color};font-size: 1em;">${
              this.series.name
            }</span>: <b>${this.custom?.totalY.toFixed(0)}%</b><br><i>${
              this.name
            }</i>:<b> ${this.custom?.percentComplete}%</b>
            `;
          } else {
            return `${this.series.name}:<b> ${this.custom?.totalY.toFixed(
              0
            )}%</b><br>`;
          }
        },

        positioner: function (labelWidth) {
          return {
            x: (this.chart.chartWidth - labelWidth) / 2,
            y: this.chart.plotHeight / 2 + 58,
          };
        },
      },

      pane: chartSeries.map((item, i) => ({
        startAngle: item.custom.startAngle,
        endAngle: item.custom.endAngle,
        background: [
          {
            outerRadius: item.data[0].radius,
            innerRadius: item.data[0].innerRadius,
            borderWidth: 0,
          },
        ],
      })),

      yAxis: chartSeries.map((item, i) => ({
        min: 0,
        max: 100,
        pane: i,
        lineWidth: 0,
        tickPositions: [],
      })),

      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled: false,
          },
        },
      },
      exporting: {
        enabled: false,
      },

      series: chartSeries,
    } as ChartOptions;
  }, [chartSeries, data]);

  if (!data) return <> </>;
  if (!currentUser?.flags.isRockCreek) return <> </>;
  //display centered
  return (
    <div
      style={{ display: "flex", justifyContent: "center" }}
      data-cy="data-coverage"
    >
      <HighChartsReact
        loading={loading}
        constructorType="chart"
        options={options}
        highcharts={HighCharts}
      />
    </div>
  );
}

export default InvestmentCompletenessChart;
