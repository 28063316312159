import { gql } from "../../graphql-types";

export const INVESTMENT_COMPLETENESS_QUERY = gql(`
  query investmentCompleteness($investmentId: Int!) {
    investmentCompleteness(investmentId: $investmentId) {
      category
      id
      data {
        id
        subCategory
        value
        description
        percentComplete
      }
    }
  }
`);
